/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';

import Service from './request';
import {
  CheckBenefitResponse,
  GetDomainsResponse,
  GetHomeBigNumbersResponse,
  GetPartnersResponse,
  GetReleasedBooksResponse,
} from './types';

export class SkeeloApi {
  private baseUrl: string;
  private collaboratorsBaseUrl: string;

  constructor() {
    this.baseUrl = process.env.NEXT_PUBLIC_SKEELO_API || '';
    this.collaboratorsBaseUrl =
      process.env.NEXT_PUBLIC_SKEELO_API_COLLABORATOR || '';
  }

  retrieveTerms = async (
    params = ''
  ): Promise<AxiosResponse<{ terms: string }, unknown>> => {
    const endpoint = '/terms/';
    const url = `${this.baseUrl}${endpoint}?${params}`;

    return Service.get(url);
  };

  retrievePolicy = async (
    params = ''
  ): Promise<AxiosResponse<{ policy: string }, unknown>> => {
    const endpoint = '/policy/';
    const url = `${this.baseUrl}${endpoint}?${params}`;

    return Service.get(url);
  };

  retrieveFaq = async (): Promise<any> => {
    const endpoint = '/faq';
    const url = `${this.baseUrl}${endpoint}`;
    return Service.get(url);
  };

  retrievePartners = async (): Promise<
    AxiosResponse<GetPartnersResponse, null>
  > => {
    const url = `https://api.skeelo.app/api/v3/carrier/carousel`;

    return Service.get(url);
  };

  checkCoupon = async (voucher: string): Promise<AxiosResponse<any, any>> => {
    Service.service.defaults.headers = {} as any;

    const endpoint = `/voucher/${voucher}/check`;
    const url = `${this.baseUrl}${endpoint}`;

    return Service.get(url) as Promise<AxiosResponse<any, any>>;
  };

  checkCollaboratorEmail = async (
    isSemPararEmail: boolean,
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    const baseUrl = isSemPararEmail
      ? process.env.NEXT_PUBLIC_SKEELO_API_AUDIOBOOK
      : this.baseUrl;
    const endpoint = `/contact/collaborator`;
    const url = `${baseUrl}${endpoint}`;

    return Service.post(url, data);
  };

  checkCode = async (code: string): Promise<AxiosResponse<any, any>> => {
    const endpoint = `/url/extend?code=${code}`;
    const url = `${this.baseUrl}${endpoint}`;

    return Service.get(url);
  };

  getDomains = async (
    term: string,
    page = 1,
    limit = 100
  ): Promise<AxiosResponse<GetDomainsResponse, null>> => {
    const params = `page=${page}&limit=${limit}&term=${term}`;
    const endpoint = '/domains';

    const url = `${this.collaboratorsBaseUrl}${endpoint}?${params}`;

    return Service.get(url);
  };

  checkBenefit = async (
    phoneNumber: string,
    document: string,
    origin?: string
  ): Promise<AxiosResponse<CheckBenefitResponse, null>> => {
    let params = `msisdn=${phoneNumber}&cpf=${document}`;

    if (origin) {
      params += `&origin=${origin}`;
    }

    const endpoint = `/subscriber/check/eligibility/universal?${params}`;

    const url = `${this.baseUrl}${endpoint}`;

    return Service.get(url);
  };

  postSendCollaboratorEmail = async (
    email: string
  ): Promise<AxiosResponse<unknown, unknown>> => {
    const body = { email };

    const endpoint = `/contact/collaborator`;
    const url = `${this.baseUrl}${endpoint}`;

    return Service.post(url, body);
  };

  postActivateCollaborator = async (
    token: string,
    document: string
  ): Promise<AxiosResponse<unknown, unknown>> => {
    const body = { token, cpf: document };

    const url = `${this.collaboratorsBaseUrl}`;

    return Service.post(url, body);
  };

  getReleasedBooks = async (
    limit = 20
  ): Promise<AxiosResponse<GetReleasedBooksResponse, null>> => {
    const url = `${this.baseUrl}/ebook/released?limit=${limit}`;

    return Service.get(url);
  };

  getHomeBigNumbers = async (): Promise<
    AxiosResponse<GetHomeBigNumbersResponse, null>
  > => {
    const url = `${this.baseUrl}/site/skeelo/home`;

    return Service.get(url);
  };
}
