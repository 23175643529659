'use client';

import { SkeeloApi } from '@services/api';
import { language } from 'i18n';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect } from 'react';

interface CodeHomeSectionProps {
  lng: language;
}

export function CodeHomeSection({ lng }: CodeHomeSectionProps) {
  const router = useRouter();
  const pathname = usePathname();

  const skeeloApi = new SkeeloApi();

  useEffect(() => {
    const regex = new RegExp(`/${lng}/`, 'gi');

    const code = pathname?.replace(regex, '') as string;

    if (code) {
      skeeloApi.checkCode(code).then(({ data }) => {
        const url = data.original_url;

        router.push(url);
      });
    }
  }, []);

  return null;
}
